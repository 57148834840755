import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import layout from "@/views/layout.vue";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        leftnav: {
            isCollapse: false,
        },
        routeArr: [], //
        thispath: "/",
        routeNav: [],
        routerpush: {},
        ispermission: [],
        token: "",
        yiweirouteList: [],
    },
    mutations: {
        changetoken(state, val) {
            state.token = val;
        },
        chengleft(state, val) {
            state.leftnav.isCollapse = val;
        },
        putrouteArr(state) {
            if (sessionStorage.getItem("routeArr")) {
                state.routeArr = sessionStorage.getItem("routeArr") ? JSON.parse(sessionStorage.getItem("routeArr")) : [];
            }
        },
        putispermission(state, val) {
            state.ispermission = val;
        },

        chengroute(state, to) {
            if (to.path == "/" || to.path == "/layout" || !to.name) {
                return;
            }
            let is = true;
            state.thispath = to.path;
            for (let i = 0; i < state.routeArr.length; i++) {
                if (state.routeArr[i].path == to.path) {
                    is = false;
                    break;
                }
            }
            if (is) {
                state.routeArr.push(to);
                setTimeout((res) => {
                    sessionStorage.setItem("routeArr", JSON.stringify(state.routeArr));
                }, 200);
            }
        },
        delpath(state, path) {
            let index = 0;
            for (let i = 0; i < state.routeArr.length; i++) {
                if (state.routeArr[i].path == path) {
                    index = i;
                    break;
                }
            }
            state.routeArr.splice(index, 1);
            setTimeout((res) => {
                sessionStorage.setItem("routeArr", JSON.stringify(state.routeArr));
            }, 200);
            if (state.routeArr.length == 0) {
                router.push({
                    path: "/layout",
                });
            } else {
                router.push({
                    path: state.routeArr[0].path,
                });
            }

            // state.routeArr
        },
        getrouteNav(state, routes) {
            state.routeNav = [];
            state.routerpush = {
                path: "/layout",
                name: "layout",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: layout,
                children: [],
            };
            let childrenroute = [];

            routes.data.forEach((item) => {
                if (item.pid || item.nonav || item.pid > 0) {
                    let ritem = {
                        path: item.path,
                        name: item.name,
                        component: () => import("@/views" + item.file_path),
                    };
                    childrenroute.push(item);
                    state.routerpush.children.push(ritem);
                } else {
                    if (item.file_path) {
                        state.routeNav.push({
                            ...item,
                            children: [],
                            component: () => import("@/views" + item.file_path),
                        });
                        let ritem = {
                            path: item.path,
                            name: item.name,
                            component: () => import("@/views" + item.file_path),
                        };
                        state.routerpush.children.push(ritem);
                        console.log();
                    } else {
                        state.routeNav.push({
                            ...item,
                            children: [],
                        });
                    }
                }
            });
            router.addRoutes([state.routerpush]);
            // state.yiweirouteList  = routes.data
            state.routeNav.forEach((it) => {
                childrenroute.forEach((item) => {
                    if (it.id == item.pid) {
                        it.children.push(item);
                    }
                });
            });
        },
    },
});
