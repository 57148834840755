<template>
    <div class="page1">
        <div class="logo-box">
            <!-- <img src="../assets/logo.png" class="logo"> -->
            <div style="color: #fff; font-size: 26px">{{ APP_NAME }}租赁管理</div>
            <div class="icon-box" style="margin-left: auto">
                <theme></theme>
            </div>
            <div class="icon-box">
                <i class="el-icon-full-screen" @click="screen"></i>
            </div>
            <div class="icon-box">
                <i class="el-icon-refresh-right" @click="$router.go(0)"></i>
            </div>
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link" style="color: #fff">
                    {{ admin.user_name }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item command="5">修改密码</el-dropdown-item> -->
                    <el-dropdown-item command="2">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-container>
            <leftNav></leftNav>
            <el-container>
                <el-header>
                    <headers></headers>
                </el-header>
                <el-main>
                    <div>
                        <el-breadcrumb separator=">" class="curmb" v-if="routeArr != ''">
                            <el-breadcrumb-item v-for="item in routeArr" :key="item.path" v-if="routeArr.length > 1">{{ item.name }}</el-breadcrumb-item>
                        </el-breadcrumb>

                        <!-- yiweirouteList -->
                        <!-- <div v-if>
							当前位置：
						</div> -->
                    </div>

                    <transition name="el-zoom-in-top">
                        <keep-alive>
                            <router-view></router-view>
                        </keep-alive>
                    </transition>
                </el-main>
            </el-container>
        </el-container>

        <el-dialog title="修改密码" :visible.sync="pwdshow" width="350px">
            <el-form label-width="90px" label-position="left">
                <el-form-item label="原密码: " prop="kdmc">
                    <el-input v-model="pwdform.oldpassword" show-password placeholder="请输入原密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码: " prop="kdmc">
                    <el-input v-model="pwdform.newpassword" show-password placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="重复新密码: " prop="kdmc">
                    <el-input v-model="pwdform.renewpassword" show-password placeholder="请重复新密码"></el-input>
                </el-form-item>
            </el-form>
            <div style="text-align: center">
                <el-button type="warning" @click="changepwd">修改</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import theme from "@/components/theme.vue";
import leftNav from "@/components/leftNav.vue";
import headers from "@/components/header.vue";
import { APP_NAME } from "@/uilts/baseInfo";

export default {
    data() {
        return {
            APP_NAME,
            fullscreen: false,
            admin: JSON.parse(sessionStorage.getItem("admin")),
            password: "",
            num: 0,
            money: 1,
            tixianshow: false,
            name: "",
            avatar: "",
            nameshow: false,
            pwdform: {
                oldpassword: "",
                newpassword: "",
                renewpassword: "",
            },
            pwdshow: false,
        };
    },
    components: {
        leftNav,
        headers,
        theme,
    },
    activated() {
        this.$store.commit("putrouteArr");
        this.admin = JSON.parse(sessionStorage.getItem("admin"));
    },
    // watch: {
    // 	'$route.path'() {
    // 		console.log(this.$route, )

    // 	}
    // },
    computed: {
        routeArr() {
            console.log(111, this.$route.path);
            let arr = [];
            console.log(11, this.$store.state.routeNav);
            this.$store.state.routeNav.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    item.children.forEach((it) => {
                        if (it.path == this.$route.path) {
                            arr = [
                                {
                                    name: item.name,
                                    path: "",
                                },
                                {
                                    name: it.name,
                                    path: it.path,
                                },
                            ];
                        }
                    });
                } else {
                    arr = [
                        {
                            name: item.name,
                            path: "",
                        },
                    ];
                }
            });
            console.log("arr", arr);
            if (arr.length == 1) {
                console.log("this.$store.state.yiweirouteList", this.$store.state.yiweirouteList);
                let ywXunhuan = this.$store.state.yiweirouteList;
                for (let xinxi in ywXunhuan) {
                    if (ywXunhuan[xinxi].path == this.$route.path) {
                        console.log("ywXunhuan[xinxi]", ywXunhuan[xinxi]);
                    }
                }
            }

            return arr;
        },
        isCollapse() {
            return this.$store.state.leftnav.isCollapse;
        },
        // routeArr() {
        // 	return this.$store.state.routeArr
        // },
        thispath: {
            get() {
                return this.$store.state.thispath;
            },
            set(value) {},
        },
    },
    methods: {
        chengleft(val) {
            this.$store.commit("chengleft", val);
        },
        getImg(val) {
            this.admin.avatar = val;
        },
        screen() {
            let element = document.documentElement;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        },
        delpath(path) {
            this.$store.commit("delpath", path);
        },
        getimg(url) {
            this.avatar = url;
        },
        tabclick(e) {
            console.log(e, 888);
            this.$router.push(e.name);
        },

        async handleCommand(e) {
            if (e == 1) {
                this.$router.push("/shopcenter");
            } else if (e == 2) {
                sessionStorage.removeItem("admin");
                sessionStorage.removeItem("routeArr");
                this.$router.push("/");
            } else if (e == 3) {
                let res = await this.$api.balanceGet();
                this.num = this.num = res == "成功" ? 0 : res;
                this.tixianshow = true;
            } else if (e == 4) {
                this.name = this.shop.name;
                this.avatar = this.shop.avatar;
                this.nameshow = true;
            } else if (e == 5) {
                this.pwdform = {
                    oldpassword: "",
                    newpassword: "",
                    renewpassword: "",
                };
                this.pwdshow = true;
            }
        },
        async shopnameupdate() {
            if (!this.avatar) {
                this.$message.error({
                    showClose: true,
                    message: "请上传店铺头像",
                });
                return;
            }
            if (!this.name) {
                this.$message.error({
                    showClose: true,
                    message: "请输入新的店铺名称",
                });
                return;
            }
            let res = await this.$api.shopnameupdate({
                name: this.name,
                avatar: this.avatar,
            });
            if (res) {
                this.shop.name = this.name;
                this.shop.avatar = this.avatar;
                sessionStorage.setItem("shop", JSON.stringify(this.shop));
                this.nameshow = false;
                this.$notify.success("修改成功");
            }
        },
        async changepwd() {
            if (!this.pwdform.oldpassword) {
                this.$message.error({
                    showClose: true,
                    message: "请输入原密码",
                });
                return;
            }
            if (!this.pwdform.newpassword) {
                this.$message.error({
                    showClose: true,
                    message: "请输入新密码",
                });
                return;
            }
            if (!this.pwdform.renewpassword) {
                this.$message.error({
                    showClose: true,
                    message: "请重复输入新密码",
                });
                return;
            }
            if (this.pwdform.oldpassword == this.pwdform.newpassword) {
                this.$message.error({
                    showClose: true,
                    message: "新密码不能与原密码相同",
                });
                return;
            }
            if (this.pwdform.newpassword != this.pwdform.renewpassword) {
                this.$message.error({
                    showClose: true,
                    message: "两次新密码不一致",
                });
                return;
            }
            this.$confirm("修改密码后将退出登录，是否确认", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let res = await this.$api.changepwd(this.pwdform);
                    if (res) {
                        this.nameshow = false;
                        this.$notify.success("修改成功");
                        sessionStorage.setItem("admin", "");
                        this.$router.push("/");
                    }
                })
                .catch(() => {});
        },
        tixian() {
            if (this.num * 1 < this.money * 1) {
                this.$message.error({
                    showClose: true,
                    message: "提现金额不能大于余额",
                });
            } else {
                this.$confirm("是否确认提现" + this.money + "元?", "提示", {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(async () => {
                        let res = await this.$api.balanceAdd({
                            money: this.money,
                        });
                        if (res) {
                            this.tixianshow = false;
                            this.money = 1;
                            this.$notify.success("操作成功");
                        }
                    })
                    .catch(() => {});
            }
        },
    },
};
</script>
<style>
.el-header {
    padding: 0 !important;
    height: auto !important;
}

.el-main {
    height: calc(100vh - 130px);
    background: #f5f5f5;
}
</style>
<style scoped>
.curmb {
    margin-bottom: 10px;
    font-size: 15px;
}

.page1 {
    min-width: 1200px;
    height: 100vh;
    background: #f5f5f5;
}

.logo-box {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background: #000;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 999;
}

.logo {
    width: auto;
    height: 50px;
    margin-right: 10px;
    border-radius: 3px;
}

.icon-box {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
    color: #fff;
}

.avatar {
    margin-right: 10px;
    margin-left: 10px;
    min-width: 50px;

    width: 50px;
    height: 50px;
}
</style>
