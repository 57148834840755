<template>
    <div
        class="page"
        :style="{
            background: `url(${require(`@/assets/${BACKGROUND_IMAGE}`)}) no-repeat`,
        }"
    >
        <b class="shan"></b>
        <b :class="{ shan1: is }"></b>
        <div class="box">
            <!-- 租赁程序管理后台 -->
        </div>
        <div class="page-cont">
            <div style="position: absolute; top: -60px; font-size: 28px">{{ APP_NAME }}运营后台</div>
            <div class="boder"></div>
            <div class="title">账号</div>
            <div class="input-box">
                <input type="text" v-model="form.username" />
            </div>
            <div class="title">密码</div>
            <div class="input-box">
                <input type="password" v-model="form.pwd" @keyup.enter="onSubmit()" />
            </div>
            <div
                style="
                    position: absolute;
                    bottom: 15px;
                    right: -25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    font-family: '楷体', '楷体_GB2312';
                    font-weight: bold;
                    font-size: 18px;
                    font-style: italic;
                    line-height: 1;
                "
            >
                <span>开启您的财富之路</span>
                <img src="../assets/dengl@2x.png" class="btn" :loading="btnloading" @click="onSubmit()" />
            </div>
        </div>
    </div>
</template>
<script>
// import { forEach } from 'core-js/js/array'
import { BACKGROUND_IMAGE, APP_NAME } from "../uilts/baseInfo";
export default {
    data() {
        return {
            BACKGROUND_IMAGE,
            APP_NAME,
            form: {
                username: "",
                pwd: "",
            },
            btnloading: true,
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入账号",
                        trigger: "blur",
                    },
                ],
                pwd: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
            },
            websock: "",
            is: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.is = true;
        }, 2000);
    },
    activated() {},

    beforeDestroy() {},
    methods: {
        async onSubmit() {
            const _this = this;
            if (!this.form.username) {
                this.$message.error({
                    showClose: true,
                    message: "请输入账号",
                });
                return;
            }
            if (!this.form.pwd) {
                this.$message.error({
                    showClose: true,
                    message: "请输入密码",
                });
                return;
            }
            let res = await this.$api.checkLogin(this.form);
            this.btnloading = false;
            if (res) {
                let xinxi = res.data;

                if (xinxi.status == 1) {
                    this.$notify.error("管理员状态不可用");
                    return;
                }
                this.$notify.success("登录成功");

                let data = res.data;
                let userInfo = {
                    token: data.token,
                    nickname: data.user_name,
                    role: data.role,
                };
                window.sessionStorage.setItem("admintoken", data.token);
                window.sessionStorage.setItem("admin", JSON.stringify(userInfo));
                window.sessionStorage.setItem("putispermission", JSON.stringify(res.data.menu_auth_list));
                this.$store.commit("putispermission", res.data.menu_auth_list);
                // this.$store.commit('changetoken',data.token)

                this.$api.getMyselfNav().then((res) => {
                    try {
                        res.data.forEach((item) => {
                            if (item.path != "") {
                                _this.$router.push(item.path);
                                throw new Error("LooInterrupt");
                            }
                        });
                    } catch (e) {
                        if (e.message !== "LooInterrupt") throw e;
                    }
                });
            }
        },
    },
};
</script>
<style></style>
<style scoped>
.page {
    width: 100vw;
    height: 100vh;
    background: url("../assets/zulehui-bg.jpg") no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
}

.box {
    position: absolute;
    bottom: 300px;
    top: -100px;
    left: 351px;
    right: 0;
    margin: auto;
    overflow: hidden;
    height: 159px;
    color: #e89c16;
    font-size: 80px;
}

@-webkit-keyframes changeImg {
    from {
        left: -50%;
    }

    100% {
        left: 120%;
    }
}

@-webkit-keyframes changeImg1 {
    from {
        left: -50%;
    }

    100% {
        left: 120%;
    }
}

.shan {
    position: absolute;
    -webkit-animation: changeImg 4s infinite 0s;
    -o-animation: changeImg 4s infinite 0s;
    animation: changeImg 4s infinite 0s;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 30%;
    left: -50%;
    height: 100%;
    content: "";
    opacity: 0.35;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    transform: skewX(-45deg);
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

.shan1 {
    position: absolute;
    -webkit-animation: changeImg1 4s infinite 0s;
    -o-animation: changeImg1 4s infinite 0s;
    animation: changeImg1 4s infinite 0s;
    top: 0;
    left: -50%;
    bottom: 0;
    margin: auto 0;
    width: 30%;
    height: 100%;
    content: "";
    opacity: 0.35;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    transform: skewX(-45deg);
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

input {
    background: none;
    outline: none;
    border: none;
}

.page-cont {
    width: 320px;
    height: 367px;
    background: #f8f8f8;
    border-radius: 20px;
    position: absolute;
    right: 400px;
    top: 200px;
    bottom: 0;
    margin: auto;
    box-sizing: border-box;
    padding: 0 40px;
}

.boder {
    position: absolute;
    top: 0;
    width: 56px;
    height: 4px;
    background: #0078ef;
    left: 40px;
}

.title {
    margin-top: 50px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #1b1b1b;
}

.input-box {
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

input {
    font-size: 16px;
}

.btn {
    width: 54px;
    height: 54px;
}

.btn:active {
    opacity: 0.9;
}
</style>
