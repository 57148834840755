<template>
    <div class="leftNav">
        <!-- <div  style="background: #000;padding:0 20px;">
		<img src="../assets/1651469562(1).png" alt="" class="logo">
	  </div> -->
        <el-menu :default-active="$route.path" class="el-menu-vertical-demo" :collapse="isCollapse">
            <template v-for="item in routeNav">
                <el-submenu :index="item.id + ''" :key="item.id" v-if="!item.nonav && !item.file_path">
                    <template slot="title">
                        <i :class="item.icon" v-if="item.icon"></i>
                        <span>{{ item.name }}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item :index="ite.path" v-for="ite in item.children" v-if="!ite.nonav" @click="gopage(ite.path)">
                            <i :class="ite.icon" v-if="ite.icon"></i>
                            {{ ite.name }}
                        </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-menu-item :index="item.path" v-if="!item.nonav && item.file_path" @click="gopage(item.path)">
                    <i :class="item.icon" v-if="item.icon"></i>
                    {{ item.name }}
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
export default {
    data() {
        return {
            shop: {},
        };
    },
    computed: {
        isCollapse() {
            return this.$store.state.leftnav.isCollapse;
        },
        routeNav() {
            return this.$store.state.routeNav;
        },
    },
    created() {
        this.shop = JSON.parse(sessionStorage.getItem("shop"));
    },
    methods: {
        gopage(path) {
            this.$router.push(path);
        },
    },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    height: calc(100vh - 60px);
    background: #fff;
}

.leftNav {
    height: calc(100vh - 60px);
    background: #fff;
}

.logo {
    width: 50px;
    height: 50px;
    text-align: center;
}
</style>
