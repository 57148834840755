import axios from "axios";
import { Message, Notification } from "element-ui";
import router from "../router/index.js";
import { BASE_URL } from "./baseInfo.js";
const http = axios.create({
    //通用请求头的地址前缀

    //本地
    // baseURL: 'http://lefenbei.com/',
    //测试服务器
    // baseURL: 'https://www.lsxx999888.com/admproj/',
    baseURL: BASE_URL + "/admproj/",
    timeout: 100000, //超时时
});

// 添加请求拦截器
http.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么

        let config1 = config;
        config1.headers = {
            "Content-Type": "application/json",
        };

        let xinxi = sessionStorage.getItem("admintoken") ? sessionStorage.getItem("admintoken") : "";
        let token = xinxi ? xinxi : "";
        // console.log('token', JSON.parse(sessionStorage.getItem('admin')))

        if (config1.data) {
            config1.data.token = token;
        } else {
            config1.data = {
                token,
            };
        }
        // if(config1.params){

        // 	config1.params.token = token
        // }else{
        // 	config1.params = {token}
        // }
        if (config.url.indexOf("userWithdrawExcelExport") > -1 || config.url.indexOf("shopWithdrawExcelExport") > -1) {
            config1.responseType = "blob";
            config1.fileName = "导出文件";
            console.log();
        }
        return config1;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
http.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        if (response.request.responseURL.indexOf("userWithdrawExcelExport") > -1 || response.request.responseURL.indexOf("shopWithdrawExcelExport") > -1) {
            if (response.data) {
                let blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                }); // 为blob设置文件类型，这里以.xlsx为例
                let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
                let a = document.createElement("a");
                a.href = url;
                a.download = response.config.fileName || "导出文件";
                a.click();
            }
            return "成功";
        }

        if (response.data) {
            console.log(111111, response.data);
            console.log(response.data.status);
            if (response.data.status == 1) {
                return response.data;
            } else if (response.data.status == -998 || response.data.status == -999) {
                Notification.error({
                    title: "登录过期",
                    message: "请重新登录",
                });
                router.replace("/");
            } else {
                Notification.error({
                    title: "错误",
                    type: "warning",
                    message: response.data.info || "操作失败",
                });
                return "";
            }
            // if (response.data.status != 1) {
            // 	console.log(11111,response.data.info)
            // 	Message.error({
            // 		showClose: true,
            // 		message: response.data.info
            // 	})
            // 	return
            // } else {
            // 	// Message.success({
            // 	// 	showClose: true,
            // 	// 	message: response.data.msg
            // 	// })
            // 	return response.data;
            // }
        }
    },
    function (error) {
        console.log(1111111, 454);
        Message.error({
            showClose: true,
            message: "请求失败",
        });
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

export default http;
