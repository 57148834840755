<template>
    <div>
        <!-- <el-upload class="avatar-uploader" action="" name="file" :show-file-list="false" :http-request="fnUploadRequest" v-loading="loading" :style="{width,height}" :on-success="handleAvatarSuccess" :class="{round:round}" :id="idkey" :before-upload="beforeUpload" multiple :on-error="err" accept="image/*" :key="idkey">
			<div class="el-upload-list--picture-card" v-if="imgUrl">
				<img :src="imgUrl" class="avatar el-upload-list__item-thumbnail" :style="{width,height}" />
				<span class="el-upload-list__item-actions" :style="{width,height}" @click.stop>
					<span class="el-upload-list__item-preview" @click.stop="handlePictureCardPreview(imgUrl)">
						<i class="el-icon-zoom-in"></i>
					</span>
					<span class="el-upload-list__item-preview" v-if="!dis">
						<i class="el-icon-upload2" @click.stop="upload"></i>
					</span>
				</span>
			</div>
			<i v-if="!imgUrl" class="el-icon-plus avatar-uploader-icon" :style="{width,height,'line-height':height}"></i>
		</el-upload> -->

        <el-upload
            v-if="isDuotu == false"
            class="avatar-uploader"
            :action="BASE_URL + '/admproj/goods/uploadFiles'"
            :data="shangchuanData"
            name="file"
            :show-file-list="false"
            v-loading="loading"
            :style="{ width, height }"
            :on-success="handleAvatarSuccess"
            :class="{ round: round }"
            :id="idkey"
            :before-upload="beforeUpload"
            multiple
            :on-error="err"
            accept="image/*"
            :key="idkey"
        >
            <div class="el-upload-list--picture-card" v-if="imgUrl">
                <img :src="imgUrl" class="avatar el-upload-list__item-thumbnail" :style="{ width, height }" />
                <span class="el-upload-list__item-actions" :style="{ width, height }" @click.stop>
                    <span class="el-upload-list__item-preview" @click.stop="handlePictureCardPreview(imgUrl)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-preview" v-if="!dis">
                        {{ dis }}
                        <i class="el-icon-upload2" @click.stop="upload"></i>
                    </span>
                </span>
            </div>
            <i v-if="!imgUrl" class="el-icon-plus avatar-uploader-icon" :style="{ width, height, 'line-height': height }"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <!-- 多图 -->
        <el-upload
            :action="BASE_URL + '/admproj/goods/uploadFiles'"
            :data="shangchuanData"
            name="file"
            list-type="picture-card"
            v-if="isDuotu == true"
            :on-success="handlePic"
            multiple
            :file-list="imgUrl"
            :on-remove="handleRemove"
        >
            <i class="el-icon-plus" />
        </el-upload>
    </div>
</template>
<script>
const OSS = require("ali-oss");
const client = new OSS({
    accessKeyId: "LTAI5tEX3sSWvqnw3mYCFoxp", // 查看你自己的阿里云KEY
    accessKeySecret: "KPFaquWyJuzBkKFC9PpfhLKHKZ1NcF", // 查看自己的阿里云KEYSECRET
    bucket: "meta-picture", // 你的 OSS bucket 名称
    region: "oss-cn-hangzhou", // bucket 所在地址，我的是 华北2 北京
});
import { BASE_URL } from "@/uilts/baseInfo";
import { Message } from "element-ui";
let xinxi = sessionStorage.getItem("admin") ? JSON.parse(sessionStorage.getItem("admin")) : "";
export default {
    name: "upload",
    props: {
        imgUrl: {
            type: [Array, String],
            default: () => "",
        },
        width: {
            type: String,
            default: () => "120px",
        },
        height: {
            type: String,
            default: () => "120px",
        },
        round: {
            type: Boolean,
            default: () => false,
        },
        dis: {
            type: Boolean,
            default: () => false,
        },
        idkey: {
            type: String,
            default: () => "avatar-uploader",
        },
        isDuotu: {
            type: Boolean,
            default: () => false,
        },
    },
    watch: {
        // imgUrl(newValue,oldValue){
        // 	console.log(newValue,oldValue)
        // 	if(this.isDuotu){
        // 		let xinxi = []
        // 		for(let i in newValue){
        // 			console.log(i)
        // 			xinxi.push({url:newValue[i]})
        // 		}
        // 		this.imgUrl = xinxi
        // 	}
        // }
    },
    data() {
        return {
            BASE_URL,
            dialogImageUrl: "",
            dialogVisible: false,
            loading: false,
            shangchuanData: {
                token: "",
            },
            disabled: false,
            pictureLists: [],
        };
    },
    created() {
        let xinxi = sessionStorage.getItem("admintoken") ? sessionStorage.getItem("admintoken") : "";
        this.shangchuanData.token = xinxi ? xinxi : "";
        console.log(this.shangchuanData);
    },
    methods: {
        //删除
        handleRemove(file) {
            const url = file.response.data.url;
            const arr = [];
            this.pictureLists.forEach((element) => {
                if (element !== url) {
                    arr.push(element);
                }
            });
            this.pictureLists = arr;
            this.$emit("getImg", this.pictureLists);
        },
        // 上传多图上传成功
        handlePic(res, file) {
            const that = this;
            this.loading = false;
            if (!res) {
                return;
            }
            console.log(1111123456, res, file);
            if (res.status == 1) {
                this.$message.success("上传图片成功");
                that.pictureLists.push(res.data.path);
                this.$emit("getImg", this.pictureLists);
            } else {
                this.$message.error(res.info);
            }
        },
        //单图上传成功
        handleAvatarSuccess(res, file) {
            this.loading = false;
            if (!res) {
                return;
            }
            console.log(1111123456, res);
            if (res.status == 1) {
                this.$message.success("上传图片成功");
                this.$emit("getImg", res.data.path);
            } else {
                this.$message.error(res.info);
            }
        },
        beforeUpload(file) {
            // 显示loading动画
            const isLt4M = file.size / 1024 / 1024 < 4;
            if (!isLt4M) {
                this.$message.error("上传图片大小不能超过 4MB!");
            }
            if (!isLt4M) {
                return isLt4M;
            } else {
                this.loading = true;
            }
        },
        handlePictureCardPreview(imgUrl) {
            console.log(1111111, imgUrl);
            this.dialogImageUrl = imgUrl;
            this.dialogVisible = true;
        },
        upload() {
            document.querySelector("#" + this.idkey + " input").click();
        },
        err(e) {
            this.loading = false;
            console.log(e);
        },
        async fnUploadRequest(options) {
            try {
                console.log(options);
                let file = options.file; // 拿到 file
                let fileName = file.name.substr(0, file.name.lastIndexOf("."));
                let date = new Date().getTime();
                let fileNames = `${date}_${fileName}`; // 拼接文件名，保证唯一，这里使用时间戳+原文件名
                // 上传文件,这里是上传到OSS的 uploads文件夹下
                client.put("uploads/" + fileNames, file).then((res) => {
                    console.log(res);
                    if (res.res.statusCode === 200) {
                        options.onSuccess(res);
                    } else {
                        options.onError("上传失败");
                    }
                });
            } catch (e) {
                options.onError("上传失败");
            }
        },
    },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.round {
    border-radius: 50%;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}

.avatar {
    width: 150px;
    height: 150px;
    display: block;
    object-fit: cover;
}
</style>
